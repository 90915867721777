'use client';

import { useEffect } from 'react';

import { Container, Heading, Link } from '@/components';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return (
    <main>
      <Container className="page_not_found">
        <section className="page_not_found__content">
          <Heading className="heading" level={1}>
            500
          </Heading>
          <p className="heading__descrip">Opps! Something went wrong</p>
          <p className="page_not_found__content__description">
            There was an error on our server.
            <br />
            If the error persists, please reach out to our support team.
          </p>
        </section>
        <section className="page_not_found__form">
          <Link className="link" href={'/'} style={{ marginTop: '20px' }}>
            Return to Home
          </Link>
          <button
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
            style={{ marginTop: '20px', textDecoration: 'underline' }}
          >
            Try again
          </button>
        </section>
      </Container>
    </main>
  );
}
